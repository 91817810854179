<template>
  <div>
    <b-tabs
      content-class="mt-0"
      ref="tabs"
      class="report-tabs"
      v-model="tabIndex"
      align="center"
    >
      <b-tab title="UPLOAD PACKING SLIP" @click="getActiveTab('upload')" v-if="permission[0].isPermission" active >
        <UploadPackingSlip ref="uploadSlip" :isGetData="getDataTab === 'upload'" />
      </b-tab>
      <b-tab title="CUSTOMER DETAILS" @click="getActiveTab('customer')" v-if="permission[1].isPermission" >
        <DownloadCustomerDetail ref="customerDetail" :isGetData="getDataTab === 'customer'" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import UploadPackingSlip from "../components/Customers/UploadPackingSlip.vue";
import DownloadCustomerDetail from "../components/Customers/DownloadCustomerDetail.vue";
// import { getChannelList } from "../Service/CommonService";
import { isAuthorized } from '../Service/CommonService';

export default {
  components: {
    UploadPackingSlip,
    DownloadCustomerDetail,
  },
  data() {
    return {
      tabIndex: 0,
      getDataTab:"",
      permission:[
        {
          title:'upload',
          isPermission:isAuthorized('allow_to_upload_packingslips')
        },
        {
          title:'customer',
          isPermission:isAuthorized('allow_to_view_customer_details')
        }
      ]
    };
  },

   created(){
    if(isAuthorized('allow_to_upload_packingslips')){
      this.getDataTab = "upload"
    }else if(isAuthorized('allow_to_view_customer_details')){
      this.getDataTab = "customer"
    }
  },

  methods:{
    getActiveTab(tabName){
      this.activeTab = tabName;
      if(this.activeTab === "upload"){
        this.$refs.uploadSlip.getData();
      }
      else if(this.activeTab === "customer"){
        this.$refs.customerDetail.getData();
      }
    }
  }
  
};
</script>

<style scoped>
@media only screen and (max-width: 768px){
  ::v-deep .report-tabs .nav{
    border-bottom: 1px solid #dee2e6 !important;
    padding-bottom: 0 !important;
    justify-content: center !important;
  }
}
</style>
