<template>
  <div>
    <b-card
      class="m-2 mt-4 desktop-return-filter"
      :body-class="'card-padding'"
      v-if="isDesktopFilterOpen"
    >
      <div class="row">
        <img
          class="mt-3 close-filter-icon"
          src="../../assets/CloseModel.svg"
          width="15px"
          @click="closeDesktopFilter"
        />

        <div class="col-md-3">
          <TreeDropdown
            :labelText="'Channels'"
            :rootOptions="channelList"
            :selectedValue="selectedChannelList"
            @selectedField="getSelectedChannel($event)"
          />
        </div>
        <div class="col-md-3">
          <label class="tree-label">Purchased Date</label>
          <date-picker
            id="purchase-date"
            v-model="selectedPurchaseDate"
            :lang="'en'"
            range
            confirm
            :shortcuts="[]"
            @clear="clearPurchaseDate($event)"
            @confirm="getPurchaseDate($event)"
          ></date-picker>
        </div>
        <div class="col-md-3">
          <!-- <label class="tree-label">SKU</label> -->
          <w-text-input
            :labelName="'SKU'"
            :fieldType="'text'"
            :disabled="false"
            :defaultValue="skuID"
            :labelStyle="'register-label'"
            @textInputChange="getSkuValue($event)"
          />
        </div>
      </div>
    </b-card>

    <div class="customer-detail-table">
      <w-customer-table
        :isFilterButton="true"
        :isCustomerDetails="true"
        :tableLoading="tableLoading"
        :tableProperties="tableProperties"
        @getSelectedPerPageEvent="getSelectedPerPage($event)"
        @getSelectedPageEvent="getSelectedPage($event)"
        @openDesktop_filterEvent="openDesktop_filter"
        @deleteCustomerEvent="showConfirmModal($event)"
        @downloadGenerateManifest="downloadCustomerDetails"
      />
    </div>

    <!-- error display popup -->
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
    <w-loading-spinner :loadSpinner="loadingWidget" :isSchedulePickup="true" />
  </div>
</template>

<script>
import moment from "moment";
import TreeDropdown from "../../widgets/TreeDropdown.vue";
import DatePicker from "vue2-datepicker";
import TableWithCheckbox from "../../widgets/TableWithCheckbox.vue";
import Modal from "../../widgets/ModalWidget";
import TextInput from "../../widgets/TextInput.vue";
import LoadingSpinner from "../../widgets/LoadingSpinner.vue";
import { getChannelList } from "../../Service/CommonService";
import { getCustomerList, deleteCustomerData, downloadCutomer } from "../../Service/CustomerService";
export default {
  components: {
    TreeDropdown,
    DatePicker,
    "w-customer-table": TableWithCheckbox,
    Modal,
    "w-text-input": TextInput,
    "w-loading-spinner": LoadingSpinner,
  },

  props:["isGetData"],

  data() {
    return {
      isDesktopFilterOpen: false, // for desktop filter
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      tableLoading: false,
      loadingWidget:false,
      selectedChannelList: [],
      channelList: [],
      startDate: "",
      endDate: "",
      selectedPurchaseDate: "",
      skuID:"",
      tableProperties: {
        fields: [
          {
            key: "buyerName",
            label: "Buyer Name",
          },

          {
            key: "phoneNumber",
            label: "Buyer Phone Number",
          },
          {
            key: "customer_address",
            label: "Buyer Address",
          },
          {
            key: "businessType",
            label: "Customer Type",
          },
          {
            key: "orderDate",
            label: "Date of Purchase",
          },
          {
            key: "customer_products",
            label: "Products",
          },
          {
            key: "customer_action",
            label: "Action",
          },
        ],
        items: [],
        perPage: 50,
        currentPage: 1,
        totalRows: 0,
        // allSelected: false,
        // showReturnDownloadBtn: true,
      },
    };
  },

  created() {
    if(this.isGetData){
      this.getData();
    }
  },

  methods: {
    getData(){
      this.getChannelList();
      this.getCustomerList();
    },

    // get channel list
    getChannelList() {
      getChannelList()
        .then((res) => {
          if (res) {
            res.forEach((element) => {
              this.channelList.push({
                id: element.channelId,
                label: element.channelName,
              });
            });
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // get customer list
    getCustomerList() {
      this.tableLoading = true;
      getCustomerList(
        this.tableProperties.currentPage - 1,
        this.tableProperties.perPage,
        this.startDate,
        this.endDate,
        this.selectedChannelList,
        this.skuID
      )
        .then((res) => {
          this.tableLoading = false;
          if (!res.errorCode) { 
            this.tableProperties.items = [];
            let resp = res;
            this.tableProperties.items = resp.content;
            this.tableProperties.totalRows = resp.totalElements;
          } else {
            this.alert = true;
            this.alertMsg = res.failureMessage;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // delete customer
    showConfirmModal(customerData) {
      this.customerID= customerData.id;
      this.alert = true;
      this.alertMsg = `Are you sure want to delete ${customerData.buyerName} ?`;
      this.alertType = "";
      this.isConfirmAlert = true;
    },
    confirmOk() {
      this.deleteCustomer(this.customerID);
    },
    // delete api call
    deleteCustomer(customerID) {
      deleteCustomerData(customerID)
        .then((res) => {
          this.alert = false;
          // this.alertMsg = res.message || "Successfully deleted";
          // this.alertType = "";
          // this.isConfirmAlert = false;
          // this.tableProperties.perPage = 50;
          // this.tableProperties.currentPage = 1;
          this.getCustomerList();
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // select channels
    getSelectedChannel(event) {
      this.selectedChannelList = event;
      this.getCustomerList();
    },

    // select purchased date
    getPurchaseDate() {
      if (this.selectedPurchaseDate.length > 1) {
        this.startDate = moment(this.selectedPurchaseDate[0]).valueOf();
        this.endDate = moment(this.selectedPurchaseDate[1]).valueOf();
        this.getCustomerList();
      }
    },
    // clear purchased date
    clearPurchaseDate() {
      this.startDate = "";
      this.endDate = "";
      this.getCustomerList();
    },

    getSkuValue(event) {
      this.skuID = event;
      this.tableProperties.currentPage = 1;
      this.getCustomerList();
    },

    // pagination
    getSelectedPerPage(perPage) {
      this.tableProperties.perPage = perPage;
      this.tableProperties.currentPage = 1;
      this.getCustomerList();
    },
    getSelectedPage(currentPage) {
      this.tableProperties.currentPage = currentPage;
      this.getCustomerList();
    },
    
    // download customer details
    downloadCustomerDetails(){
      this.loadingWidget = true;
      downloadCutomer(this.selectedChannelList, this.startDate, this.endDate, this.skuID)
        .then((res) => {
          this.loadingWidget = false;
          // if (res.manifest_url) {
          //   location.href = res.manifest_url;
          // }
        })
        .catch((err) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // open a desktop filer
    openDesktop_filter() {
      this.isDesktopFilterOpen = !this.isDesktopFilterOpen;
    },
    closeDesktopFilter() {
      this.isDesktopFilterOpen = false;
    },
    // for close modal
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
  },
};
</script>

<style scoped>
.close-filter-icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 0px;
  z-index: 2;
}
.customer-detail-table ::v-deep tbody{
  white-space: nowrap;
}
.customer-detail-table ::v-deep .userTable table tbody tr td:nth-child(3){
  text-align: left;
}
.customer-detail-table ::v-deep thead th{
    text-align: center;
} 
.customer-detail-table ::v-deep tbody td{
    text-align: center;
} 
.customer-detail-table ::v-deep .shipment-buttons{
  margin-top: 0 !important;
}
::v-deep .input-group .form-control{
  height: 37px !important;
}

/* for responsive layout */
@media only screen and (max-width: 768px) {
  .desktop-return-filter ::v-deep .mx-range-wrapper {
    width: 100%;
  }
  .desktop-return-filter ::v-deep .mx-calendar {
    width: 100%;
  }
  .desktop-return-filter ::v-deep .mx-calendar-content {
    width: 100%;
  }
  .desktop-return-filter ::v-deep .mx-datepicker-popup {
    left: 0px !important;
  }
}

@media only screen and (max-width: 340px){
  .customer-detail-table ::v-deep .table-pagination .page-link{
    min-width: 15px !important;
  }
}
@media only screen and (max-width: 380px){
  .customer-detail-table ::v-deep .filter-btn{
    margin-right: 0 !important;
  }
  .customer-detail-table ::v-deep .shipment-buttons{
    font-size: 11px;
    padding: 3px !important;
  }
  .customer-detail-table ::v-deep .shipment-buttons img{
    width: 12px;
  }
}
@media only screen and (max-width: 768px) {
  .mobile-top-space {
    margin-top: 15px !important;
  }
  .desktop-return-filter {
    z-index: 2;
    margin-top: 2rem;
  }
  .card-padding {
    margin-top: 10px;
  }
}
</style>
