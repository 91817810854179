import { Apihelper } from "./API/apiHelper.js";
const apiHelper = new Apihelper();
const customerBaseUrl = process.env.VUE_APP_CUSTOMER_SERVER_URL;


export const getCustomerList = (pageNo, pageSize, startDate, endDate, channelIds, skuID) => {
    let uri = `${customerBaseUrl}/api/v1/customer/customer-details?page-no=${pageNo}&page-size=${pageSize}`;  

    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-id=', channelIds);
    }
    if (startDate) {
        uri = uri.concat('&from-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&to-date=', endDate); 
    }
    if (skuID) {
        uri = uri.concat('&sku=', skuID);
    }

    return apiHelper.get(uri);

  };

// delete customer
export const deleteCustomerData = (id) => {
    const uri = `${customerBaseUrl}/api/v1/customer/delete/customer-details?id=${id}`;
    return apiHelper.remove(uri);
}

// upload packing slip
export const uploadPackingSlip = (formData, channelId) => {
    const uri = `${customerBaseUrl}/api/v1/customer/customer-details?channel-id=${channelId}`;
    return apiHelper.post(uri, formData);
}

// download customer details
export const downloadCutomer = (channelIds, startDate, endDate, skuId) => {
    const uri = `${customerBaseUrl}/api/v1/customer/download/customer-details?`;  
    
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-id=', channelIds);
    }
    if (startDate) {
        uri = uri.concat('&from-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&to-date=', endDate); 
    }
    if (skuId) {
        uri = uri.concat('&sku=', skuId);
    }
    
    return apiHelper.downloadFile(uri);
}
