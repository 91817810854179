<template>
  <div class="mt-5 mx-2 upload-packing-slip bg-white">
    <div class="ml-4">
    <!-- channel filter -->
      <div class="row">
        <div class="col-lg-3 col-md-4 col-8">
          <label class="tree-label">Channel</label>
          <w-dropdown
            :label-text="''"
            :labelId="'customer-channel'"
            :selectedValue="selectedChannelList"
            :selectOption="channelList"
            @selectedOption="getSelectedChannel($event)"
          />
        </div>
      </div>
    <!-- channel filter end -->
      <div class="">
        <div class="row">
          <div class="col-lg-6 col-md-7 col-10">
            <b-form-file
             style="display:none"
              id="upload-custom-file"
              accept=".pdf"
              multiple
              @change="fileChange"
            ></b-form-file>
            <label for="upload-custom-file" class="file-drop-box">
              <img src="../../assets/SVG/pdf.svg" width="30px">
              <span>Drop your Files here or click to upload Supports: PDF</span>
            </label>
          </div>
        </div>
        <div class="d-flex align-items-end packing-buttons pb-4">
          <w-button-input
            :isDisabled="isSubmitBtnDisabled"
            :buttonClass="'auth-button'"
            :buttonStyle="'auth-button-style'"
            @buttonClicked="uploadPackingSlip()"
            :label="'Upload'"
          />
          <w-button-input
            class="ml-3"
            :buttonClass="'request-demo'"
            :buttonStyle="'auth-button-style'"
            @buttonClicked="cancelPackingSlip()"
            :label="'Cancel'"
          />
        </div>
      </div>
    </div>

    <!-- error modal  -->
    <w-loading-spinner
      :loadSpinner="isloadingWidget"
      :isSchedulePickup="true"
    />
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
  </div>
</template>

<script>
import ButtonInput from "../../widgets/InputButton.vue";
import Modal from "../../widgets/ModalWidget";
import LoadingSpinner from "../../widgets/LoadingSpinner";
import { uploadPackingSlip } from "../../Service/CustomerService.js";
import { getChannelList } from "../../Service/CommonService";
import Dropdown from "../../widgets/Dropdown.vue";
export default {
  components: {
    "w-button-input": ButtonInput,
    Modal,
    "w-loading-spinner": LoadingSpinner,
    "w-dropdown": Dropdown,
  },

  props:["isGetData"],

  data() {
    return {
      tableLoading: false,
      isloadingWidget: false,

      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      errorMsg: "",

      attachments: [],
      form: new FormData,
      channelList: [],
      selectedChannelList: "",
    };
  },

  created(){
    if(this.isGetData){
      this.getData();
    }
  },

  computed: {
    isSubmitBtnDisabled() {
      if (this.attachments.length > 0 && !this.selectedChannelList == "") {
        return false;
      }
      return true;
    },
  },

  methods: {
    getData(){
      this.getChannelList();
    },

   // get channel list
    getChannelList() {
      getChannelList()
        .then((res) => {
          if (res) {
            res.forEach((element) => {
              this.channelList.push({
                value: element.channelId,
                text: element.channelName,
              });
            });
          }
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // select channels
    getSelectedChannel(event) {
      this.selectedChannelList = event;
    },
    
    cancelPackingSlip() {
      this.attachments = [];
    },

    fileChange(e){
      let selectedFiles = e.target.files;

      if(!selectedFiles.length){
        return false
      }
      for(let i=0; i<selectedFiles.length; i++){
        this.attachments.push(selectedFiles[i])
      }
    },

    // upload files
    uploadPackingSlip() {
      this.isloadingWidget = true;

      for(let i=0; i<this.attachments.length; i++){
        this.form.append('file', this.attachments[i])
      }
      
      uploadPackingSlip(this.form, this.selectedChannelList)
        .then((res) => {
          this.isloadingWidget = false;
          this.alert = true;
          this.alertMsg = res.response || "Successfully Uploaded";
          this.alertType = "Success";
          this.isConfirmAlert = false;

          this.selectedChannelList="";
        })
        .catch((err) => {
          this.isloadingWidget = false;
          this.alert = true;
          this.alertMsg = err.message || "Oops something went wrong?";
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // modal close
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
  },
};
</script>

<style scoped>
.upload-packing-slip {
  background: #fff;
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 5px;
}
.upload-packing-slip .file-drop-box{
  border: 1px solid #ced4da;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  box-shadow: 0 1px 3px rgb(0 0 0 / 16%);
  background: #f5f5f5 0 0 no-repeat padding-box;
}
.upload-packing-slip .file-drop-box span{
  text-align: center;
  padding: 20px 10px 0 10px;
  color: #aeaeae;
  font-size: 12px;
}
.upload-packing-slip ::v-deep .request-demo {
  background: #fff;
  border: 1px solid #005d84;
  padding: 0.5rem !important;
}
::v-deep .custom-file-input {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .upload-packing-slip {
    margin: 40px 20px 0 20px;
  }
  .upload-packing-slip div:first-child {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 430px) {
  .packing-buttons ::v-deep button {
    width: 7rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 760px){
  .packing-buttons ::v-deep .auth-button{
      height: 42px;
  }
}

</style>

